import React, { useRef, useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import {
  Geometry,
  GeometryCollection,
  Properties,
  Feature,
} from "@turf/helpers"
import MapGL, { Viewport } from "@urbica/react-map-gl"
import { Spinner } from "../components/Spinner"
import { useFormik } from "formik"
import * as Yup from "yup"
import useLocalStorage from "../hooks/useLocalStorage"

import TeaserMapSidebar from "../sections/TeaserMap/TeaserMapSidebar"
import TeaserMapFooter from "../sections/TeaserMap/TeaserMapFooter"
import { MapVisualization } from "../components/MapVisualization"
import { PARCEL_LAYER_MIN_ZOOM } from "../components/ParcelSelectLayer"

import { DEFAULT_ZOOM_LEVEL, getViewportFromFeatures } from "../shared/utils"

import {
  useQueryParam,
  useUserCurrentPosition,
  useTeaserAssessment,
  useAddressLatLng,
} from "../hooks"
import { convertMultiPolygonToPolygons, calcAcres } from "../utils"

type FeatureProperties = Feature<Geometry | GeometryCollection, Properties>[]
type Assessment = { category: string; n_programs: number }

interface TeaserMapProps {
  initialFeatures?: FeatureProperties
}

const TeaserMap = ({ initialFeatures = [] }: TeaserMapProps) => {
  const mapRef = useRef<MapGL | null>(null)
  const queryClient = useQueryClient()

  const [acreage, setAcreage] = useState<number>(0)
  const [parcelIds, setParcelIds] = useState<string[]>([])
  const [, setFeatureStorage] = useLocalStorage<FeatureProperties>(
    "storedFeatures",
    []
  )

  const [viewport, setViewport] = useState<Viewport>(
    getViewportFromFeatures(initialFeatures) as Viewport
  )

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      features: initialFeatures,
    },
    validationSchema: Yup.object({
      features: Yup.array(),
    }),
    // DEV: Avoid `enableReinitialize=true`, see https://app.asana.com/0/0/1200063952843264/f
    enableReinitialize: false,
    onSubmit: () => {
      navigate("/getting-started")
    },
  })

  // Viewport defaults hierarchy:
  // 1. Center of property address
  // 2. User location with fallback to USA
  const [viewportResolved, setViewportResolved] = useState(false)

  // (1) Get the address from the URL and center map on it
  const searchAddress = useQueryParam("search") || "" // Get the address passed in through query string
  const decodedAddress = searchAddress ? decodeURIComponent(searchAddress) : "" // Decode if searchAddress is present

  const {
    data: propertyAddressLatLng,
    fetchStatus: propertyAddressLatLngFetchStatus,
  } = useAddressLatLng<{ lat: number; lng: number }, Error>(decodedAddress, {
    enabled: viewportResolved === false && decodedAddress !== "",
  })

  // (2) Get the user's location and center map on it
  const { data: userPosition, fetchStatus: userPositionFetchStatus } =
    useUserCurrentPosition<
      {
        latitude: number
        longitude: number
        default: boolean
      },
      Error
    >({
      enabled: viewportResolved === false,
    })

  // Resolver for viewport (1 -> 2)
  useEffect(() => {
    // If we have already determined the viewport, stop early
    if (viewportResolved) {
      return
    }

    if (propertyAddressLatLngFetchStatus === "fetching") {
      return
    }

    // If we have an address (+ status = success), use it
    if (propertyAddressLatLng) {
      setViewport((oldViewport) => ({
        latitude: propertyAddressLatLng.lat,
        longitude: propertyAddressLatLng.lng,
        zoom: oldViewport?.zoom || PARCEL_LAYER_MIN_ZOOM,
      }))
      setViewportResolved(true)
      return
    }

    if (userPositionFetchStatus === "fetching") {
      return
    }

    if (userPosition) {
      setViewport((oldViewport) => ({
        latitude: userPosition.latitude,
        longitude: userPosition.longitude,
        zoom: userPosition.default
          ? 4
          : oldViewport?.zoom || DEFAULT_ZOOM_LEVEL,
      }))
    }
    setViewportResolved(true)
  }, [
    viewportResolved,
    propertyAddressLatLng,
    propertyAddressLatLngFetchStatus,
    userPositionFetchStatus,
    userPosition,
  ])

  useEffect(() => {
    // Set the acreage state
    setAcreage(calcAcres(formik.values.features))
    let featureParcelIds: string[] = [] // Initialize the array
    formik.values.features.forEach(
      (feature: Feature<Geometry | GeometryCollection, Properties>) => {
        const parcelId = feature.properties?.parcelId
        if (typeof parcelId === "string") {
          featureParcelIds.push(parcelId)
        }
      }
    )
    // There are overlapping parcels in the features, so we need to remove duplicates
    featureParcelIds = Array.from(new Set(featureParcelIds))
    setParcelIds(featureParcelIds)

    // Store features in local storage
    setFeatureStorage(formik.values.features)
  }, [formik.values.features, setFeatureStorage])

  // Fetch the assessment data
  const shouldFetchAssessment =
    formik.dirty && formik.values.features.length !== 0
  const { data: assessmentData, status: assessmentDataStatus } =
    useTeaserAssessment(
      queryClient,
      {
        features: formik.values.features,
      },
      {
        enabled: shouldFetchAssessment,
      }
    )

  let assessmentDataReturn: Assessment[] =
    (assessmentData as Assessment[]) || []
  if (shouldFetchAssessment) {
    assessmentDataReturn = assessmentData as Assessment[]
  }

  const assessmentStatus = shouldFetchAssessment ? assessmentDataStatus : "idle"

  const handleCreateFeatures = (features: FeatureProperties) => {
    // We need to limit this to just one feature
    formik.setFieldValue("features", [...formik.values.features, ...features])
  }

  // DEV: `update` will sometimes come back with a feature with only 2 points, it gets a `delete` call shortly after
  //   but we still fire a failing HTTP request before then =/
  //   https://app.asana.com/0/1199976942355619/1200117645347666/f
  const filterEmptyFeatures = (features: Feature<Geometry, Properties>[]) => {
    return features.filter((feature) => feature.geometry.coordinates.length > 0)
  }
  const ensurePolygon = (features: FeatureProperties) => {
    // if none MultiPolygons, return directly to avoid unnecessary mapping
    if (!features.some((feature) => feature.geometry.type === "MultiPolygon"))
      return features

    return features.map(convertMultiPolygonToPolygons).flat()
  }
  const handleUpdateFeatures = (updatedFeatures: FeatureProperties) => {
    const features = formik.values.features.map(
      (currFeature: Feature<Geometry | GeometryCollection, Properties>) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const updatedFeature = updatedFeatures.find(
          (updatedFeature) => updatedFeature.id === currFeature.id
        )
        return updatedFeature || currFeature
      }
    )
    formik.setFieldValue(
      "features",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      filterEmptyFeatures(ensurePolygon(features))
    )
  }

  const handleDeleteFeatures = (deletedFeatures: any[]) => {
    const features = formik.values.features.filter(
      (currFeature: Feature<Geometry | GeometryCollection, Properties>) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const wasDeleted = deletedFeatures.find(
          (deletedFeature) => deletedFeature.id === currFeature.id
        )
        return !wasDeleted
      }
    )
    formik.setFieldValue("features", features)
  }

  const handleClearFeatures = () => {
    formik.setFieldValue("features", [])
  }

  return (
    <>
      {viewportResolved === false ? (
        <div className="flex flex-auto items-center justify-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="absolute inset-0 z-10 bg-white teaser-map">
          <TeaserMapSidebar
            acreage={acreage}
            assessmentData={assessmentDataReturn || []}
            assessmentStatus={assessmentStatus}
            parcelIds={parcelIds}
            onSubmit={formik.handleSubmit}
          />
          <MapVisualization
            ref={mapRef}
            features={formik.values.features}
            viewport={viewport}
            setViewport={setViewport}
            onViewportChange={setViewport}
            onFeatureCreate={handleCreateFeatures}
            onFeatureUpdate={handleUpdateFeatures}
            onFeatureDelete={handleDeleteFeatures}
            onFeatureClear={handleClearFeatures}
            showParcelSelect={true}
            showParcelDraw={false}
            showParcelUpload={false}
            decodedAddress={decodedAddress}
            displayMapEditingTools={true}
          />
          <TeaserMapFooter
            showAcreageSpinner={false}
            acreage={acreage}
            onSubmit={formik.handleSubmit}
          />
        </div>
      )}
    </>
  )
}
export default TeaserMap
