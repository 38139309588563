import { useContext, useEffect, useState } from "react"
import { useParams, Navigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import Error404 from "./Error404"
import {
  useAccountUsers,
  useDashboardAccounts,
  useIsMultiAccount,
  useProfile,
  useAccountId,
  useProjectList,
  useAttestations,
  useAccount,
  useRoi,
  useStackables,
  useAccountProperty,
  useGetTileUrls,
  useGetParcelAssessment,
  useAccountNotifications,
  useAccountUrlPrefix,
} from "../hooks"
import useLocalStorage from "../hooks/useLocalStorage"
import {
  filterProjectsData,
  getProjectData,
  getProjectsData,
  getStackableProjectsData,
} from "../shared/utils"
import { ProjectsContext } from "../context/ProjectsContext"
import { LANDOWNER_STATUS, PROJECT_SORTING } from "../shared/constants"
import ProjectDetailsMain from "../sections/ProjectDetails/ProjectDetailsMain"
import { ProgramType, ProjectListTypes } from "@/types/program"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"
import { NotificationTypes } from "@/types/notifications"
import { AccountProperty } from "@/types/property"
import { TileURLsTypes } from "@/types/tiles"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import { Profile } from "@/types"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { AccountTypes } from "@/types/account"
import { AttestationsType } from "@/types/attestations"

const ProjectDetails = () => {
  const {
    state: {
      category,
      govtProjectsOn,
      ineligibleProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
      searchProjects,
    },
  } = useContext(ProjectsContext)

  const { projectId } = useParams()
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [sortBy] = useLocalStorage(
    "sortProjectsBy",
    PROJECT_SORTING.RECENTLY_ADDED
  )
  const location = useLocation()
  const isMultiAccount = useIsMultiAccount()
  const accountUrlPrefix = useAccountUrlPrefix()
  const [roiCalcDefaults, setRoiCalcDefaults] =
    useState<CalculatorInputsTypes | null>(null)
  const [acceptInvite, setAcceptInvite] = useState<boolean | null>(null)

  /*** Queries and Mutations ***/

  const {
    data,
    status: queryStatus,
    isLoading: queryIsLoading,
  } = useProjectList<ProjectListTypes, Error>(queryClient, accountId, {})

  const { data: stackableProjectsIds } = useStackables<number[], Error>(
    queryClient,
    accountId,
    projectId as string
  )

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const { data: tileData, isLoading: tileIsLoading } = useGetTileUrls<
    TileURLsTypes,
    Error
  >(accountId, projectId as string)

  const { data: parcelData, isLoading: parcelIsLoading } =
    useGetParcelAssessment<FeatureCollection<Geometry, Properties>, Error>(
      accountId,
      projectId as string
    )

  const { data: attestationsData, isLoading: attestationsIsLoading } =
    useAttestations<AttestationsType, Error>(
      queryClient,
      accountId,
      projectId as string,
      {}
    )

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { isLoading: accountUsersIsLoading } = useAccountUsers(
    queryClient,
    "_single",
    {
      enabled: !isMultiAccount,
    }
  )

  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts<DashboardAccountsTypes, Error>(queryClient, {
      enabled: isMultiAccount,
    })

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: roiData, isSuccess: roiIsSuccess } = useRoi<RoiTypes, Error>(
    queryClient,
    accountId,
    projectId as string
  )

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  /*** Data constants ***/

  const projectsData: ProgramType[] = getProjectsData(data)

  const filteredProjectsData: ProgramType[] = filterProjectsData(
    projectsData,
    sortBy,
    category,
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects
  )

  const projectData: ProgramType = getProjectData(
    data,
    projectsData,
    Number(projectId)
  )

  const similarPrograms: ProgramType[] = filteredProjectsData?.filter(
    (project) => {
      const projectFilter =
        project.type === projectData?.type &&
        project.id !== projectData?.id &&
        (project.badge_display === LANDOWNER_STATUS.eligible ||
          project.badge_display === LANDOWNER_STATUS.information_needed)

      return projectFilter
    }
  )

  const stackablePrograms: ProgramType[] = getStackableProjectsData(
    stackableProjectsIds,
    projectsData
  )

  const showRoi: boolean =
    typeof roiData === "object" &&
    roiData.total_revenue !== undefined &&
    roiData.total_profit !== undefined &&
    roiData.yearly_revenue !== undefined &&
    roiData.yearly_cost !== undefined &&
    roiData.yearly_profit !== undefined &&
    roiData.yearly_npv !== undefined

  const invite: NotificationTypes | undefined = notifications?.find(
    (notification) =>
      notification.key === "pd_invite" &&
      notification?.extra_fields?.project_id === Number(projectId)
  )

  const isLoading = [
    profileIsLoading,
    accountsIsLoading,
    accountUsersIsLoading,
    queryIsLoading,
    attestationsIsLoading,
    accountIsLoading,
    propertyIsLoading,
    notificationsIsLoading,
  ]

  /*** Effects ***/

  useEffect(() => {
    if (roiData && !roiCalcDefaults && roiIsSuccess) {
      setRoiCalcDefaults(roiData?.calculator_inputs)
    }
  }, [roiData, roiCalcDefaults, roiIsSuccess])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }, [projectId])

  useEffect(() => {
    if (location?.state?.acceptInvite) {
      setAcceptInvite(true)
    }
    if (location?.state?.acceptInvite === false) {
      setAcceptInvite(false)
    }

    return () => setAcceptInvite(null)
  }, [location?.state?.acceptInvite])

  // DEV: MA users with no accounts get an error, so we redirect them to the account creation page
  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  if (queryStatus === "success" && projectData === undefined) {
    if (account && account?.is_member === false) {
      // DEV: Navigate to become-a-member page if user is a non-member & doesn't have access to this project
      return <Navigate replace to={`${accountUrlPrefix}/become-a-member`} />
    }
    return <Error404 />
  }

  return (
    <ProjectDetailsMain
      isLoading={isLoading}
      projectsData={projectsData}
      profile={profile}
      projectData={projectData}
      attestationsData={attestationsData}
      account={account}
      similarPrograms={similarPrograms}
      stackablePrograms={stackablePrograms}
      acceptInvite={acceptInvite}
      setAcceptInvite={setAcceptInvite}
      invite={invite}
      filteredProjectsData={filteredProjectsData}
      roiData={roiData as RoiTypes}
      showRoi={showRoi}
      tileIsLoading={tileIsLoading}
      parcelIsLoading={parcelIsLoading}
      tileData={tileData}
      parcelData={parcelData}
      property={property}
      roiCalcDefaults={roiCalcDefaults as CalculatorInputsTypes}
    />
  )
}

export default ProjectDetails
