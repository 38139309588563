import { useContext, useEffect, ComponentType, ReactNode } from "react"
import assert from "assert"
import { useQueryClient } from "@tanstack/react-query"
import * as Sentry from "@sentry/react"
import { OptimizelyContext } from "@optimizely/react-sdk"
import {
  Routes,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  generatePath,
} from "react-router-dom"

import { LandOwnersRouter } from "./routers/LandOwnersRouter"
import { PartnersRouter } from "./routers/PartnersRouter"
import Error404 from "./pages/Error404"
import { Spinner } from "./components/Spinner"
import { VerifyEmailOuterContent } from "./sections/VerifyEmailContent"
import { useAccessToken, useSessionStore } from "./stores"
import {
  useAccountId,
  useIsMultiAccount,
  useRefresh,
  useProfile,
  useAccountUrlPrefix,
} from "./hooks"
import { useAppcues } from "./hooks/useAppcues"
import {
  identifyUserForIntegrations,
  resetUserForIntegrations,
  sendAnalyticsEvent,
} from "./api/integrations"
import ProjectsProvider from "./context/ProjectsProvider"
import PartnersProvider from "./context/PartnersProvider"
import { Profile } from "./types"
import { OptimizelyContextInterface } from "@optimizely/react-sdk/dist/Context"

const VITE_APP_PARTNER_VIEW = import.meta.env.VITE_APP_PARTNER_VIEW
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const ProtectedRoute = () => {
  const location = useLocation()
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()
  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })
  const isLoggedIn = !!accessToken

  if (profile && !profile?.is_email_verified) {
    return (
      <Navigate
        replace
        to="/getting-started"
        state={{ from: location, doesUserExist: true }}
      />
    )
  }

  if (!isLoggedIn) {
    return <Navigate replace to="/getting-started" state={{ from: location }} />
  }

  return <Outlet />
}

interface AccountComponentProps {
  allowMissingId?: boolean
  redirectPath?: string
  component: ComponentType<any>
  render?: () => ReactNode
  children?: ReactNode
}

export const AccountComponent = ({
  allowMissingId = false,
  redirectPath,
  component: Component,
  render,
  children,
}: AccountComponentProps) => {
  assert(!render, "AccountComponent#render not supported")
  assert(!children, "AccountComponent#children not supported")

  // Fetch all our React hooks at the top to avoid violating rule of hooks (i.e. conditional execution)
  // DEV: We need to be inside `render` or `component` of React-Router so `params` are loaded
  // DEV: We need to be a Component to handle React complaining about hooks in non-components
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const inaccessibleAccountIds = useSessionStore(
    (state) => state.inaccessibleAccountIds
  )
  const location = useLocation()

  // If we're attempting to access an account-less URL but are multi-account, then redirect
  // DEV: This will spam our Sentry logs otherwise, https://app.asana.com/0/1199976942355619/1201067257507853/f
  if (isMultiAccount && accountId === "_single") {
    if (redirectPath) {
      return <Navigate replace to={redirectPath} state={{ from: location }} />
    }
    // Allow us to ignore this check on pages like `/accounts/new`
    if (allowMissingId === false) {
      return <Navigate replace to="/accounts" />
    }
  }

  // If we explicitly don't have access to our account, then render a 404
  // DEV: We could relocate this into a `Main` layout based on a toggle but this is equally as good
  if (inaccessibleAccountIds[accountId]) {
    return <Error404 />
  }

  // Otherwise, render our component normally
  return <Component />
}

export const SharedAuth = () => {
  const isMultiAccount = useIsMultiAccount()
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()
  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })

  if (accessToken && profile?.is_email_verified) {
    return <Navigate replace to={isMultiAccount ? "/accounts" : "/"} />
  }

  return <Outlet />
}

export const ProgramsRoutes = () => (
  <ProjectsProvider>
    <Outlet />
  </ProjectsProvider>
)

export const PartnersRoutes = () => (
  <PartnersProvider>
    <Outlet />
  </PartnersProvider>
)

interface RedirectProps {
  to: string
}

export const Redirect = ({ to }: RedirectProps) => {
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    navigate(generatePath(to, params), { replace: true })
  }, [navigate, to, params])

  return null
}

const RedditPixel = () => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()

  useEffect(() => {
    if (window.rdt) {
      if (location.pathname === "/getting-started") {
        window.rdt("track", "ViewContent")
      } else if (
        location.pathname ===
        `${accountUrlPrefix}/onboarding/property-boundaries`
      ) {
        window.rdt("track", "Lead")
      }
    }
  }, [accountUrlPrefix, location.pathname])

  return null
}

export const App = () => {
  const { identified, setIdentified } = useSessionStore((state) => state)
  const accessToken = useAccessToken()
  const { optimizely } =
    useContext<OptimizelyContextInterface>(OptimizelyContext)
  const queryClient = useQueryClient()

  // DEV: This hook will take care of setting `accessToken` initially
  const { status } = useRefresh({
    // Avoid `refetchOnWindowFocus` explicitly as causes re-renders, which can cancel form submissions
    //   https://app.asana.com/0/1198952737412966/1200007329716665/f
    refetchOnWindowFocus: false,
    retry: 1,
    meta: {
      refresh: true,
    },
  })

  // When our user logs in, bind their Sentry data
  // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })

  useAppcues({ enabled: !!accessToken && !!profile, profile })

  useEffect(() => {
    // DEV: We can't check `profile` directly as it's not refiring for invalid when there's no data
    //   Our query isn't nested in the `if` because of the rule of hooks :sparkles:
    if (accessToken) {
      if (profile) {
        Sentry.setUser({ email: profile.email })

        // Identify the user for other third-party integrations. We already do this at Login and SignUp
        // explicitly, and want to keep doing it there because the UTM parameters at those pages
        // are important to pass on. But we also need to identify in the case where a user automatically
        // logs in with their refresh token cookie and bypasses both Login and Signup, so we do that here.
        if (optimizely) {
          identifyUserForIntegrations(
            profile.email,
            optimizely,
            identified,
            setIdentified,
            profile
          )
          sendAnalyticsEvent({
            action: "app-entry",
            label: "Landowners Logged In pageview",
          })
        }
      }
    } else {
      // When our user logs out, unbind their Sentry data
      Sentry.setUser(null)

      // Reset the user for other third-party integrations
      if (optimizely) {
        resetUserForIntegrations(optimizely, identified, setIdentified)
      }
    }
  }, [accessToken, profile, optimizely, identified, setIdentified])

  return (
    <>
      {status === "pending" ? (
        <div className="bg-gray-0 h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        // DEV: This must run after our `status` check, as it only runs *once* on page load
        //   so we need a logged in state to detect + run email verification on
        // DEV: Due to scrubbing + redirects occuring at lower levels,
        //   we need to perform email verification at this top level
        //   e.g. new user redirects to /onboarding when visiting /?confirm=email..., https://app.asana.com/0/0/1202342868765014/f
        <VerifyEmailOuterContent>
          {VITE_APP_PARTNER_VIEW === "1" ? (
            <PartnersRouter />
          ) : (
            <>
              <RedditPixel />
              <LandOwnersRouter />
            </>
          )}
        </VerifyEmailOuterContent>
      )}
    </>
  )
}

export default App
