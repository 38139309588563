import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/react"
import {
  createInstance,
  OptimizelyProvider,
  setLogger,
  setLogLevel,
} from "@optimizely/react-sdk"
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import { QueryClientProvider } from "./context/QueryClientProvider"
import ReactGA from "react-ga4"
import { GoogleOAuthProvider } from "@react-oauth/google"

import App from "./App"
import "./style/style.css"

// TypeScript interfaces and types
declare global {
  interface Window {
    optimizelyDatafile?: string
    __SENTRY__?: any
  }
}

// Set up Sentry ASAP so we capture everything
// https://sentry.io/silviaterra/ncapx-platform/getting-started/javascript-react/
const NODE_ENV = import.meta.env.MODE
Sentry.init({
  // DEV: If trying to test locally, re-enable this DSN and remove localhost filtering
  //   https://sentry.io/settings/ncx/projects/ncapx-platform/filters/data-filters/
  dsn:
    NODE_ENV === "production"
      ? "https://9fe11a8c08c5403195e3ac7674e696d3@o523171.ingest.sentry.io/5645029"
      : undefined,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
      filterKeys: ["ncapx-platform"],
      behaviour: "drop-error-if-exclusively-contains-third-party-frames",
    }),
  ],
  environment: import.meta.env.VITE_APP_SENTRY_ENV || NODE_ENV,
  release: import.meta.env.VITE_APP_COMMIT_REF,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "127.0.0.1",
    /^https:\/\/beta-bark-api\.ncx\.com/,
    /^https:\/\/bark-api\.ncx\.com/,
  ],

  // Session replay config:
  // https://docs.sentry.io/platforms/javascript/guides/react/session-replay
  // We set the sample rate to be 0% because we don't really
  // need non-error sessions in Sentry
  replaysSessionSampleRate: 0.0,
  // We capture 100% of sessions where an error occurs
  replaysOnErrorSampleRate: 1.0,
  // filter known noisy but unactionable errors from 3rd party scripts
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    "ResizeObserver",
    "Failed to fetch",
    "Fetch is aborted",
    "WorkerGlobalScope",
    "Error fetching datafile",
  ],
})

// Set up Google Analytics with cross-domain tracking
// https://github.com/react-ga/react-ga/issues/296#issuecomment-680128111
// DEV: We'd warn "[react-ga] gaTrackingID is required in initialize()" without `isGATestMode` logic
//   https://app.asana.com/0/1198952737412966/1202301024788594/f
const isGATestMode = !import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID
ReactGA.initialize(
  isGATestMode
    ? "mock-ga-id"
    : (import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID as string),
  {
    // DEV: `testMode` stops `analytics.js` from loading as well as sending pageview tracking/etc
    //   https://github.com/codler/react-ga4#reference
    testMode: isGATestMode,
  }
)

// set optimizely log config before instantiating the client to suppress unhelpful warnings
setLogLevel("error")
setLogger({
  log: (level, message) => {
    Sentry.captureMessage(message, {
      level: level as unknown as Sentry.SeverityLevel,
    })
  },
})

// Instantiate an Optimizely client, we'll set the user once logged in
const optimizely = createInstance({
  datafile: window.optimizelyDatafile,
  sdkKey: import.meta.env.VITE_APP_OPTIMIZELY_SDK_KEY,
})

const rootElement = document.getElementById("root")
const root = createRoot(rootElement as HTMLElement)

root &&
  root.render(
    <StrictMode>
      <GoogleOAuthProvider clientId="658405665817-0kjlejcv02a4lgng6s8gupvml7eucm3p.apps.googleusercontent.com">
        <QueryClientProvider>
          <BrowserRouter>
            <OptimizelyProvider optimizely={optimizely}>
              <App />
            </OptimizelyProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </StrictMode>
  )
