import { useCallback, useContext } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from "reakit/Disclosure"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import NavBarInboxLink from "../sections/Inbox/NavBarInboxLink"
import { SlideInNav } from "./SlideInNav"
import { PlatformUserMenu } from "./PlatformUserMenu"
import { useIsMultiAccount, useAccountId, useAccount } from "../hooks"
import { circleQuestionMark } from "../shared/icons"
import NCXLogo from "../images/NCX---Logo---Full---White.png"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { NavLayoutContext } from "../context/NavLayoutContext"
import { useSessionStore } from "../stores"
import { AccountTypes } from "@/types/account"

interface NavBarTypes {
  isLoggedIn: boolean
  isEmailVerified: boolean
  partnersNav?: boolean
  notifications: any[]
}

interface NavLinksTypes {
  href: string
  exact: boolean
  title: string
}

const PARTNERS_TOPBAR_LINKS: NavLinksTypes[] = [
  {
    href: "/partners",
    exact: true,
    title: "Partners",
  },
]
const PARTNERS_SLIDE_IN_LINKS: NavLinksTypes[] = [
  {
    href: "/partners",
    exact: true,
    title: "Partners",
  },
]
const SINGLE_ACCOUNT_TOPBAR_LINKS: NavLinksTypes[] = [
  { href: "/", exact: true, title: "Dashboard" },
  {
    href: "/programs/page/1",
    exact: true,
    title: "Programs",
  },
]
const SINGLE_ACCOUNT_SLIDE_IN_LINKS: NavLinksTypes[] = [
  { href: "/", exact: true, title: "Dashboard" },
  {
    href: "/programs/page/1",
    exact: true,
    title: "Programs",
  },
  { href: "/settings", exact: false, title: "Settings" },
]
const MULTI_ACCOUNT_TOPBAR_LINKS = (accountId: string) => {
  return accountId === "_single"
    ? [{ href: "/accounts", exact: true, title: "Accounts" }]
    : [
        { href: `/accounts/${accountId}`, exact: true, title: "Dashboard" },
        {
          href: `/accounts/${accountId}/programs/page/1`,
          exact: true,
          title: "Programs",
        },
        { href: "/accounts", exact: true, title: "Accounts" },
      ]
}
const MULTI_ACCOUNT_SLIDE_IN_LINKS = (accountId: string) => {
  return accountId === "_single"
    ? [{ href: "/accounts", exact: true, title: "Accounts" }]
    : [
        { href: `/accounts/${accountId}`, exact: true, title: "Dashboard" },
        {
          href: `/accounts/${accountId}/programs/page/1`,
          exact: true,
          title: "Programs",
        },
        { href: "/accounts", exact: true, title: "Accounts" },
        {
          href: `/accounts/${accountId}/settings/account-info`,
          exact: true,
          title: "Settings",
        },
      ]
}

export const NavBar = ({
  isLoggedIn,
  isEmailVerified,
  partnersNav = false,
  notifications = [],
}: NavBarTypes) => {
  const { clearInaccessibleAccountIds, inaccessibleAccountIds } =
    useSessionStore()
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const location = useLocation()
  const disclosure = useDisclosureState({ animated: true })
  const isMultiAccount = useIsMultiAccount()
  const { ncxLogoRef } = useContext(NavLayoutContext) ?? { ncxLogoRef: null }

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const { data: account } = useAccount<AccountTypes, Error>(
    queryClient,
    accountId,
    {
      enabled: queryEnabled,
    }
  )

  const newMessages =
    notifications.filter(
      (notification) =>
        notification.key === "pd_invite" &&
        (notification?.viewed_at === null ||
          notification?.viewed_at === undefined)
    ).length > 0

  let topbarLinks: NavLinksTypes[]
  let slideInLinks: NavLinksTypes[]

  if (partnersNav) {
    topbarLinks = PARTNERS_TOPBAR_LINKS
    slideInLinks = PARTNERS_SLIDE_IN_LINKS
  } else if (isMultiAccount) {
    topbarLinks = MULTI_ACCOUNT_TOPBAR_LINKS(accountId)
    slideInLinks = MULTI_ACCOUNT_SLIDE_IN_LINKS(accountId)
  } else {
    topbarLinks = SINGLE_ACCOUNT_TOPBAR_LINKS
    slideInLinks = SINGLE_ACCOUNT_SLIDE_IN_LINKS
  }

  const activeTopbarLink = topbarLinks.find(
    (link) => location.pathname === link.href
  )

  const zIndex = isEmailVerified ? "z-10" : "z-browserMaximum"

  let logoLink = "/"

  if (partnersNav) {
    logoLink = "/partners"
  } else if (isMultiAccount) {
    logoLink = "/accounts"
  }

  const isOnboarding = location.pathname.includes("/onboarding")
  const showLinks = isEmailVerified && !isOnboarding

  const handleClick = useCallback(() => {
    if (inaccessibleAccountIds[accountId]) {
      clearInaccessibleAccountIds()
    }
  }, [accountId, clearInaccessibleAccountIds, inaccessibleAccountIds])

  return (
    <>
      <DisclosureContent {...disclosure} className="slide-in-nav md:hidden">
        <SlideInNav
          disclosure={disclosure}
          links={slideInLinks}
          onClick={handleClick}
        />
      </DisclosureContent>

      <nav
        className={`sticky md:relative top-0 md:top-auto bg-charcoal-500 shadow shrink-0 ${zIndex}`}
      >
        <div className="relative px-5 mx-auto flex items-center justify-between h-16">
          <div className="md:hidden">
            {showLinks ? (
              <Disclosure
                {...disclosure}
                className="btn2 px-2 py-1 text-white hover:bg-brand-dark"
              >
                <span className="sr-only">Open menu</span>
                <i aria-hidden="true" className="fas fa-bars h-6 w-6" />
              </Disclosure>
            ) : (
              <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
            )}

            {activeTopbarLink && (
              <span className="text-white ml-2 hidden sm:inline">
                {activeTopbarLink?.title}
              </span>
            )}
          </div>

          <div className="hidden h-full  md:flex md:items-center">
            {/* DEV: p-2 increases hit area, -ml-2 + -mr-2 counteract left/right padding increase */}
            {isOnboarding ? (
              <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
            ) : (
              <Link
                ref={ncxLogoRef}
                to={logoLink}
                className="shrink-0 p-2 -ml-2 -mr-2"
                onClick={handleClick}
              >
                <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
              </Link>
            )}
          </div>
          <div className="flex flex-row items-center text-lg">
            <ul className="hidden text-white ml-10 md:flex md:items-center">
              {showLinks ? (
                <>
                  {topbarLinks.map((link) => (
                    <li className="h-16 flex flex-row" key={link.href}>
                      <NavLink
                        to={link.href}
                        className={({ isActive }) =>
                          cx(
                            "flex items-center justify-center text-base w-[132px] h-full font-semibold hover:bg-nav-active gap-1",
                            {
                              "bg-nav-active border-b-4 border-white": isActive,
                            }
                          )
                        }
                        onClick={handleClick}
                        end
                      >
                        {link.title === "Programs" && !account?.is_member ? (
                          <FontAwesomeIcon icon={faLock} size="xs" />
                        ) : null}

                        {link.title}
                      </NavLink>
                    </li>
                  ))}
                  <NavBarInboxLink
                    partnersNav={partnersNav}
                    isMultiAccount={isMultiAccount}
                    accountId={accountId}
                    newMessages={newMessages}
                    onClick={() => {
                      if (inaccessibleAccountIds[accountId]) {
                        clearInaccessibleAccountIds()
                      }
                    }}
                  />
                </>
              ) : null}

              <a
                href="https://help.ncx.com/hc/en-us"
                className="h-16 flex flex-row text-white items-center font-semibold hover:bg-nav-active px-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={circleQuestionMark as IconProp}
                  size="lg"
                />
              </a>
            </ul>

            <NavBarInboxLink
              partnersNav={partnersNav}
              isMultiAccount={isMultiAccount}
              accountId={accountId}
              newMessages={newMessages}
              className="md:hidden"
              onClick={handleClick}
            />

            {/* DEV: All pages using main layout should be logged in, except 404 which requires this */}
            {isLoggedIn && (
              <PlatformUserMenu
                isEmailVerified={isEmailVerified}
                partnersNav={partnersNav}
                isMember={account?.is_member}
              />
            )}
          </div>
        </div>
      </nav>
    </>
  )
}
