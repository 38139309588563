import MainLayout from "./_layouts/Main"
import AdvisorSignupPay from "../sections/AdvisorSignup/AdvisorSignupPay"
import AdvisorySignupTestimonials from "../sections/AdvisorSignup/AdvisorySignupTestimonials"
import AdvisorySignupSamples from "../sections/AdvisorSignup/AdvisorySignupSamples"

const AdvisorSignup = () => {
  return (
    <MainLayout contentBg="white" verifyUserEmailVariant="generic">
      <div className="container px-4 pt-[92px] pb-[132px]">
        <h1 className="text-4xl md:text-[50px] text-charcoal-500 text-center font-medium leading-[124%] md:leading-[110%] tracking-0.4 md:tracking-[1px]">
          Book an expert consultation
        </h1>

        <AdvisorSignupPay />

        <AdvisorySignupTestimonials />

        <AdvisorySignupSamples />
      </div>
    </MainLayout>
  )
}

export default AdvisorSignup
