import { useContext, useEffect, useRef } from "react"
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { findKey } from "lodash"

import MainLayout from "./_layouts/Main"
import Skeleton from "../sections/ProjectListing/Skeleton"
import {
  useAccount,
  useAccountId,
  useAccountNotifications,
  useAccountUsers,
  useDashboardAccounts,
  useGetAttestationQuizes,
  useIsMultiAccount,
  useProfile,
  useProjectList,
  useProjectTypes,
  useQueryParam,
  useAccountUrlPrefix,
} from "../hooks"
import useLocalStorage from "../hooks/useLocalStorage"
import {
  filterProjectsData,
  getProjectsData,
  getProjectTypeFilters,
} from "../shared/utils"
import {
  PROJECT_ACTIONS,
  PROJECT_SORTING,
  PROJECT_SORTING_QS,
} from "../shared/constants"
import { ProjectsContext } from "../context/ProjectsContext"
import ProjectListingContent from "../sections/ProjectListing/ProjectListingContent"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../types/constants"
import {
  NotificationTypes,
  PDInviteExtraFieldsTypes,
} from "../types/notifications"
import { ProjectDataTypes, ProjectListTypes } from "../types/program"
import { AccountUsers } from "../types/accountUsers"
import { Profile } from "../types"
import { EligibilityQuizzes } from "../types/eligibility"
import { FilterType } from "../components/FilterChips"
import { AccountTypes } from "@/types/account"

interface ProgramTypes {
  project_types: ProgramCategoryType
}

export type DropdownOptionsType =
  | "Recently added"
  | "Verified"
  | "Eligibility"
  | "Enrollment deadline"
  | "Potential earnings"
  | "Most popular"

const dropdownOptions: DropdownOptionsType[] = [
  PROJECT_SORTING.RECENTLY_ADDED as DropdownOptionsType,
  PROJECT_SORTING.MOST_POPULAR as DropdownOptionsType,
  PROJECT_SORTING.VERIFIED as DropdownOptionsType,
  PROJECT_SORTING.ELIGIBILITY as DropdownOptionsType,
  PROJECT_SORTING.ENROLLMENT_DEADLINE as DropdownOptionsType,
  PROJECT_SORTING.POTENTIAL_EARNINGS as DropdownOptionsType,
]
const PageSize: number = 12

export const ProjectListing = () => {
  const {
    state: {
      category,
      govtProjectsOn,
      ineligibleProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
      searchProjects,
    },
    dispatch,
  } = useContext(ProjectsContext)

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const listingRef = useRef<HTMLDivElement | null>(null)
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const { pageId } = useParams()
  const { data, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const [sortBy, setSortBy] = useLocalStorage<string>(
    "sortProjectsBy",
    PROJECT_SORTING.RECENTLY_ADDED
  )
  const sortByOverride = useQueryParam("sortBy")
  const categoryOverride = useQueryParam("category")
  const keywordOverride = useQueryParam("keyword")

  const { data: projectTypes, isLoading: projectTypesIsLoading } =
    useProjectTypes<ProgramTypes, Error>(queryClient, accountId, {})

  useEffect(() => {
    const sort =
      PROJECT_SORTING_QS[sortByOverride as keyof typeof PROJECT_SORTING_QS]
    if (sort) {
      setSortBy(sort)
    } else if (sortBy === null) {
      setSortBy(PROJECT_SORTING.RECENTLY_ADDED)
    }
    if (categoryOverride && projectTypes?.project_types) {
      if (projectTypes.project_types.includes(categoryOverride)) {
        dispatch({
          type: PROJECT_ACTIONS.setCategory,
          payload: categoryOverride,
        })
      }
    }
    if (keywordOverride) {
      dispatch({
        type: PROJECT_ACTIONS.setSearchProjects,
        payload: keywordOverride,
      })
    }
  }, [
    dispatch,
    setSortBy,
    sortBy,
    sortByOverride,
    categoryOverride,
    projectTypes,
    keywordOverride,
  ])

  useEffect(() => {
    if (location?.state?.quizProjectCategory !== undefined) {
      dispatch({
        type: PROJECT_ACTIONS.setCategory,
        payload: location?.state?.quizProjectCategory,
      })
    }
  }, [dispatch, location?.state?.quizProjectCategory])

  const isMultiAccount = useIsMultiAccount()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)
  const { isLoading: accountUsersIsLoading } = useAccountUsers<
    AccountUsers,
    Error
  >(queryClient, "_single", {
    enabled: !isMultiAccount,
  })
  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient, {
      enabled: isMultiAccount,
    })

  // DEV: Automatically Scroll to top of the listing container when navigating between the pages by using the bottom navigation
  useEffect(() => {
    if (
      listingRef &&
      listingRef?.current &&
      window.scrollY > listingRef?.current?.offsetTop
    ) {
      listingRef?.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [pageId])

  const projectsData: ProjectDataTypes[] =
    filterProjectsData(
      getProjectsData(data),
      sortBy,
      category,
      govtProjectsOn,
      ineligibleProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
      searchProjects
    ) || []

  const handleFirstPageRedirect: () => void = () =>
    navigate(
      isMultiAccount
        ? `/accounts/${accountId}/programs/page/1`
        : `/programs/page/1`,
      { replace: true }
    )

  const projectsDataIneligibleOn = filterProjectsData(
    getProjectsData(data),
    sortBy,
    category,
    govtProjectsOn,
    true,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects
  )

  const projectsDataIneligibleOnLength: number =
    projectsDataIneligibleOn?.length

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  const invites = notifications?.filter(
    (notification) => notification.key === "pd_invite"
  )

  const invitesData: NotificationTypes[] | undefined = invites?.reduce(
    (acc: NotificationTypes[], notification: NotificationTypes) => {
      const project = getProjectsData(data).find(
        (project: ProjectDataTypes) =>
          project.id ===
          (notification.extra_fields as PDInviteExtraFieldsTypes)?.project_id
      )

      if (project) {
        acc.push({
          ...notification,
          extra_fields: {
            ...notification.extra_fields,
            image_url: project.image_url,
            landowner_status: project.landowner_status,
          } as PDInviteExtraFieldsTypes,
        })
      } else {
        acc.push({
          ...notification,
        })
      }

      return acc
    },
    []
  )

  const projectTypeFilters: FilterType[] = getProjectTypeFilters(
    projectTypes,
    category
  )

  const { data: quizesData, isLoading: quizesIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  const quizCategory =
    quizesData &&
    (findKey(
      quizesData,
      (d) => d.project_type === category
    ) as EligibilityQuizCategoryType)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  // DEV: Navigate to become-a-member page if user is a non-member
  if (account && account?.is_member === false) {
    return <Navigate replace to={`${accountUrlPrefix}/become-a-member`} />
  }

  // DEV: MA users with no accounts get an error, so we redirect them to the account creation page
  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  return (
    <MainLayout
      isLoading={[
        profileIsLoading,
        accountsIsLoading,
        accountUsersIsLoading,
        projectListIsLoading,
        projectTypesIsLoading,
        notificationsIsLoading,
        quizesIsLoading,
        accountIsLoading,
      ]}
      contentBg="white"
      verifyUserEmailVariant="generic"
      loader={<Skeleton />}
    >
      <ProjectListingContent
        data={data}
        profile={profile}
        projectsData={projectsData}
        invitesData={invitesData}
        handleFirstPageRedirect={handleFirstPageRedirect}
        projectTypeFilters={projectTypeFilters}
        dropdownOptions={dropdownOptions}
        sortBy={sortBy}
        setSortBy={setSortBy}
        quizesData={quizesData}
        quizCategory={quizCategory}
        projectsDataIneligibleOnLength={projectsDataIneligibleOnLength}
        pageId={pageId}
        PageSize={PageSize}
        listingRef={listingRef}
      />
    </MainLayout>
  )
}

export default ProjectListing
